import { Button, Link, Stack, Typography } from '@mui/material';
import GoogleLogo from '../../components/GoogleLogo';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { FormSignUp, SignupMethod } from '../../types';
import SignUpEditForm from './SignUpEditForm';
import SignUpEmailForm from './SignUpEmailForm';
import SignUpMethods from './SignUpMethods';
import SignUpProviderForm from './SignUpProviderForm';
import { appActions } from './app-slice';

function SignUp() {
  const { signupFormType, awaitingAuthProvider, authProvider, dbUser } =
    useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const onEmailFormSubmit = (data: FormSignUp) => {
    dispatch(
      appActions.signUpWithEmail({
        ...data,
        method: signupFormType,
        phoneNumber: data.phoneNumber.replace(/[^\d+]+/g, ''),
      })
    );
  };

  const onAuthProviderFormSubmit = (data: FormSignUp) => {
    if (!dbUser?.id) throw new Error('Erro salvando dados');
    dispatch(
      appActions.saveUserData({
        phoneNumber: data.phoneNumber.replace(/[^\d+]+/g, ''),
        id: dbUser.id,
      })
    );
  };

  const handleSignupMethodSelect = (method: SignupMethod) => {
    dispatch(appActions.setAuthProvider(method));
    if (method === 'email') {
      dispatch(appActions.setSignupFormType(method));
    } else {
      dispatch(appActions.signUpWithAuthProvider(method));
    }
  };

  return (
    <Stack direction="column" gap={2}>
      {!dbUser && signupFormType === undefined ? (
        <>
          {awaitingAuthProvider ? (
            <>
              <Stack direction="column" gap={2} alignItems="center">
                <Typography variant="body1" textAlign="center">
                  Uma janela pop-up foi aberta para que você possa entrar de
                  forma segura com o provedor.
                </Typography>
                <Typography variant="body1" textAlign="center">
                  Estamos aguardando a sua autorização.
                </Typography>
                <GoogleLogo />
              </Stack>
            </>
          ) : (
            <>
              <Typography variant="body1" textAlign="center">
                Você está a alguns segundos de abrir sua conta!
              </Typography>
              <Typography variant="body1" textAlign="center">
                Escolha como quer se registrar:
              </Typography>
              <SignUpMethods onMethodSelect={handleSignupMethodSelect} />
              <Button href="/login">Já possuo uma conta</Button>
              <Typography variant="caption" textAlign="center">
                Ao se registrar, você declara que concorda com os nossos{' '}
                <Link
                  target="blank"
                  href="https://zetaone.com.br/docs/termos-e-condicoes-de-uso"
                >
                  Termos de uso
                </Link>{' '}
                e a nossa{' '}
                <Link
                  target="blank"
                  href="https://zetaone.com.br/docs/politica-de-privacidade"
                >
                  Política de privacidade
                </Link>
                .
              </Typography>
            </>
          )}
        </>
      ) : (
        <>
          {dbUser && dbUser.phoneNumber ? (
            <>
              <SignUpEditForm />
            </>
          ) : (
            <>
              <Typography variant="body1" textAlign="center">
                Você está a alguns segundos de abrir sua conta!
              </Typography>
              <Typography variant="body1" textAlign="center">
                Por favor complete os dados abaixo:
              </Typography>
              {signupFormType === 'email' ? (
                <SignUpEmailForm onSubmit={onEmailFormSubmit} />
              ) : (
                <SignUpProviderForm onSubmit={onAuthProviderFormSubmit} />
              )}
            </>
          )}
        </>
      )}
      {authProvider !== undefined && (
        <Button
          onClick={() => {
            dispatch(appActions.setError(''));
            dispatch(appActions.setSignupFormType(undefined));
            dispatch(appActions.setAuthProvider());
          }}
        >
          Escolher outro método
        </Button>
      )}
    </Stack>
  );
}

export default SignUp;
