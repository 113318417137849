'use strict';

import { app, auth } from './firebase';

let API_VERSION = 'v2';

export type ApiCall = {
  url: string;
  headers: Record<string, string>;
  timeout: number;
};

export const getBaseUrl = (local: boolean) => {
  if (local)
    return `http://localhost:3001${
      API_VERSION === 'v1' ? '' : `/${API_VERSION}`
    }`;
  const { projectId } = app.options;
  let baseUrl = '';
  switch (projectId) {
    case 'condomi-prod':
      baseUrl = `https://api.zetaone.com.br/${API_VERSION}`;
      break;
    case 'condomi-stg':
      baseUrl = `https://api-h.zetaone.com.br/${API_VERSION}`;
      break;
    case 'condomi-dev':
      baseUrl = `https://api-d.zetaone.com.br/${API_VERSION}`;
      break;
    case 'condomi-dev2':
      baseUrl = `https://api-d2.zetaone.com.br/${API_VERSION}`;
      break;
  }
  return baseUrl;
};

export const getApi = async ({ path }: { path: string }): Promise<ApiCall> => {
  let headers;
  const local = localStorage.getItem('br.com.zetaone.localApi') === 'true';

  let baseUrl = getBaseUrl(local);
  const token = await auth.currentUser?.getIdToken();
  const bearer = `Bearer ${token}`;

  if (local) {
    headers = {
      'X-Apigateway-Api-Userinfo': (token ?? '').split('.')[1],
    };
  } else {
    headers = {
      Authorization: bearer,
    };
  }
  const url = `${baseUrl}${path}`;
  return { url, headers, timeout: 50000 };
};
