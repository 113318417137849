import { Box, Stack, Typography } from '@mui/material';

function Active() {
  return (
    <Stack direction="column" justifyContent="center" p={4} gap={2}>
      <Typography variant="h6" textAlign="center">
        Conta ativada!
      </Typography>
      <Box
        p={2}
        display="flex"
        justifyContent="center"
        sx={{ '& img': { maxWidth: '200px' } }}
      >
        <img src="images/check.svg" alt="Logo" />
      </Box>
      <Typography variant="h6" textAlign="center">
        Você já pode utilizar o sistema!
      </Typography>
      <Typography variant="body1" textAlign="center">
        Verifique as instruções que você recebeu na sua caixa de entrada para
        começar a utilizar!
      </Typography>
    </Stack>
  );
}

export default Active;
